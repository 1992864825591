<template>
  <div>Logout</div>
</template>

<script>
export default {
  name: 'Logout',
  data() {
    return {
      toast_opts: {
        position: 'top-right',
      },
    };
  },
  beforeMount() {
    this.$store.dispatch('logout_user');
    this.$router.push({ name: 'Main' });
  },
};
</script>

<style scoped></style>
